<script>
import { permissionService } from "../../../helpers/backend/permission.service"
// import { roleService } from '../../../helpers/backend/role.service';
export default {
  data() {
    return {
      form: {
        id: null,
        // role_id: null,
        method: "GET",
        module: null,
      },
      // roleOptions: [{ text: "Select One", value: null }],
      methodOptions: ["GET", "POST", "PUT", "DELETE", "PATCH"],
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created(){
    // this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      permissionService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Insert/Update Role. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.id = null
      // this.form.role_id = null
      this.form.method = 'GET'
      this.form.module = ""
      
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.method.$el.focus()
        this.busy = true
        permissionService.getById(id).then(
          (data) => {
            if (data.permission != null) {
              this.form.id = data.permission.id
              // this.form.role_id = data.permission.role.id
              this.form.method = data.permission.method
              this.form.module = data.permission.module
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get permission to update. Error : user not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get permission to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
    // fetchData(){
    //   roleService.getAll().then(
    //     (data) => {
    //       this.roleOptions = this.roleOptions.concat(
    //         data.roles.map(function (mType) {
    //           return { text: mType.name, value: mType.id }
    //         })
    //       )
    //       //alert(typeof(this.machine_types))
    //     },
    //     (err) => {
    //       this.showDismissibleAlert = true
    //       this.error = "Failed to get role. Error : " + err
    //     }
    //   )
    // },
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <!-- <b-form-group
                id="input-group-name"
                label="Role Name :"
                label-for="input-name"
              >
                <b-form-select
                  id="input-1"
                  v-model="form.role_id"
                  :options="roleOptions"
                  required
                ></b-form-select>
              </b-form-group> -->

              <b-form-group
                id="input-group-2"
                label="Method:"
                label-for="input-2"
              >
                <!-- <b-form-input
                  id="input-2"
                  v-model="form.method"
                  type="text"
                  placeholder="Method"
                  required
                  ref="method"
                ></b-form-input> -->
                <b-form-select
                  id="input-1"
                  v-model="form.method"
                  :options="methodOptions"
                  ref="method"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Module:"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.module"
                  type="text"
                  placeholder="Module"
                  required
                  ref="module"
                ></b-form-input>
              </b-form-group>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div>
</template>



